import axios from 'axios'
import StorageService from '@/services/StorageService'

const PlaidService = {

  async getLinkToken (redirectUri, id, companyId) {
    return axios({
      url: axios.defaults.baseURL + 'plaid/link/initialize',
      method: 'POST',
      data: JSON.stringify({ id: +id, redirect_uri: redirectUri })
    }).then((response) => {
      // save the latest link token in storage for retrieval after OAuth redirect
      StorageService.set('plaidLinkToken', response.data.info)
      StorageService.set('plaidLinkTokenCompanyId', +companyId)
      return Promise.resolve(response.data.info)
    }).catch((err) => {
      console.dir(err)
      return Promise.resolve(null)
    })
  },

  async saveLinkToken (data) {
    return axios({
      url: axios.defaults.baseURL + 'plaid/link/create',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async updateLinkToken (data) {
    return axios({
      url: axios.defaults.baseURL + 'plaid/link/renew',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async getCompany (id) {
    return axios({
      url: axios.defaults.baseURL + 'plaid/company',
      method: 'POST',
      data: JSON.stringify({ company_id: id })
    })
  },

  async saveAccountMap (map) {
    return axios({
      url: axios.defaults.baseURL + 'plaid/account/save',
      method: 'POST',
      data: JSON.stringify(map)
    })
  },

  async removePlaidItem (id) {
    return axios({
      url: axios.defaults.baseURL + 'plaid/link/remove',
      method: 'POST',
      data: JSON.stringify({ id: +id })
    })
  },

  async queueImport (data) {
    return axios({
      url: axios.defaults.baseURL + 'plaid/transactions/import',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async saveCoding (transactions) {
    return axios({
      url: axios.defaults.baseURL + 'plaid/transactions/save',
      method: 'POST',
      data: JSON.stringify(transactions)
    })
  },

  async settleTransaction (transactionId) {
    return axios({
      url: axios.defaults.baseURL + 'plaid/transactions/settle',
      method: 'POST',
      data: JSON.stringify({ id: +transactionId })
    })
  },

  async getTransactions (data) {
    return axios({
      url: axios.defaults.baseURL + 'plaid/transactions/search',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async deleteTransaction (id) {
    return axios({
      url: axios.defaults.baseURL + 'plaid/transactions/delete',
      method: 'POST',
      data: JSON.stringify({ id: +id })
    })
  },
  async deleteTransactions (items) {
    return axios({
      url: axios.defaults.baseURL + 'plaid/transactions/multidelete',
      method: 'POST',
      data: JSON.stringify({ items: items })
    })
  },

  async recodeTransaction (id) {
    return axios({
      url: axios.defaults.baseURL + 'plaid/transactions/recode',
      method: 'POST',
      data: JSON.stringify({ id: +id })
    })
  },

  async processQueue (companyId) {
    return axios({
      url: axios.defaults.baseURL + 'plaid/transactions/process',
      method: 'POST',
      data: JSON.stringify({ company_id: +companyId })
    })
  },

  async synchronize (data) {
    return axios({
      url: axios.defaults.baseURL + 'plaid/transactions/synchronize',
      method: 'POST',
      data: JSON.stringify(data)
    })
  }
}

export default PlaidService
