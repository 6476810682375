<template>
  <b-button
    :variant="variant"
    :disabled="opened"
    @click="plaid"
  >{{ buttonTitle }}</b-button>
</template>
<script>
import PlaidService from '@/services/PlaidService'
import StorageService from '@/services/StorageService'

export default {
  name: 'PlaidLink',

  props: {
    companyId: {
      type: [String, Number],
      default: null
    },
    itemId: {
      type: [String, Number],
      default: null
    },
    variant: {
      type: String,
      default: 'success'
    },
    buttonTitle: {
      type: String,
      default: 'Plaid'
    }
  },

  data () {
    return {
      loading: false,
      opened: false, // disables button when dialog opening or open
      created: false, // true if new item linked, refresh event is emitted on close
      linkhandler: null,
      linktoken: null,
      linktokencompanyid: null,
      oauthstateid: null,
      receivedRedirectUri: null
    }
  },

  mounted () {
    if (this.oauthstateid) {
      console.log('PlaidLink: mounted ' + this.oauthstateid)
    }
    if (this.$route.query && this.$route.query.oauth_state_id && !this.oauthstateid) {
      this.oauthstateid = this.$route.query.oauth_state_id
      this.linktoken = StorageService.get('plaidLinkToken')
      this.linktokencompanyid = StorageService.get('plaidLinkTokenCompanyId')
      this.receivedRedirectUri = 'https://mysbsbooks.com/admin/company/plaid'
      this.$nextTick(() => {
        this.plaid()
      })
    }
  },

  methods: {
    async plaid () {
      if (this.loading || this.opened) {
        console.log('already open')
        return false
      }
      this.loading = true
      if (!this.receivedRedirectUri) {
        // this.linktoken = await PlaidService.getLinkToken(location.href.replace(location.search, ''), this.itemId)
        this.linktoken = await PlaidService.getLinkToken('https://mysbsbooks.com/admin/company/plaid', this.itemId, this.companyId)
      }
      if (this.linktoken !== null) {
        console.log('opening handler for ' + this.linktoken)
        this.linkhandler = window.Plaid.create({
          token: this.linktoken,
          onSuccess: this.onSuccess,
          onLoad: this.onLoad,
          onExit: this.onExit,
          onEvent: this.onEvent,
          receivedRedirectUri: this.receivedRedirectUri
        })
        this.loading = false
      } else {
        this.loading = false
        this.opened = false
        console.dir('unable to generate link token')
      }
    },

    onLoad () {
      // open this bitch
      if (!this.opened) {
        this.opened = true
        this.linkhandler.open()
      }
    },

    onEvent (eventName, metadata) {
      console.log('onEvent: ' + eventName)
      if (metadata.error) {
        console.dir(metadata)
      }
    },

    onSuccess (token, metadata) {
      // We have a new plaid item, save it
      console.log('onSuccess: token is ' + token)
      console.dir(metadata)
      this.$updateloading(1)
      const data = { id: this.itemId, token: token, company_id: (+this.companyId > 0) ? +this.companyId : this.linktokencompanyid, metadata: metadata }
      if (this.itemId) {
        PlaidService.updateLinkToken(data).then(() => {
          this.created = true
          this.opened = false
          if (this.oauthstateid) {
            location.href = location.href.replace(location.search, '')
          }
        }).finally(() => {
          this.$updateloading(-1)
        })
      } else {
        PlaidService.saveLinkToken(data).then(() => {
          this.created = true
          this.opened = false
          if (this.oauthstateid) {
            location.href = location.href.replace(location.search, '')
          }
        }).finally(() => {
          this.$updateloading(-1)
        })
      }
    },

    onExit (err, metadata) {
      console.dir('onExit: ' + err)
      console.dir(metadata)
      this.opened = false
      if (this.created) {
        this.$emit('refresh')
      }
    }
  }
}
</script>
